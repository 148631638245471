import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/public/risk-control',
    pathMatch: 'full'
  },
  {
    path: 'public',
    loadChildren: './public/public.module#PublicModule',
  },
  {
    path: 'index',
    loadChildren: './index/index.module#IndexModule'
  },
  {
    path: 'service',
    loadChildren: './service-center/service-center.module#ServiceCenterModule'
  },
  {
    path: '***',
    loadChildren: './index/index.module#IndexModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
